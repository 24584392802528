<template>
  <section id="portfolio">

    <div class="intro-wrap">

      <div class="row narrow section-intro with-bottom-sep">
        <div class="col-twelve">
          <h1>Katalyst Project Media</h1>
          <h1></h1>

          <p class="lead"></p>
        </div>
      </div> <!-- end row section-intro -->

    </div> <!-- end intro-wrap -->

    <div class="row portfolio-content">
      <div class="col-twelve">
        <div id="folio-wrap" class="bricks-wrapper" style="background-color: black;">

          <div class="brick folio-item">
            <div class="item-wrap" data-src="portfolio/gallery/14_projects.jpg" data-sub-html="#01" >
              <a href="#" class="overlay">
                <img src="portfolio/14_projects_small.jpg" alt="EVAC">
                <div class="item-text">
                  <span class="folio-types">
                    Funding Support
                  </span>
                  <h3 class="folio-title">End Violence Against Children Fund</h3>
                </div>
              </a>
              <a href="https://www.end-violence.org/" class='details-link' title="details">
                <i class="icon-link"></i>
              </a>
            </div> <!-- end item-wrap -->

            <div id="01" class='hide'>
              <h4>EVAC</h4>
              <p>The End Violence Fund serves as a vehicle for change on the ground. We provide financial support to programmes around the world <a href="https://www.end-violence.org/fund/">Details</a></p>
            </div>
          </div> <!-- end folio-item -->

          <div class="brick folio-item">
            <div class="item-wrap " data-src="portfolio/gallery/Project_VIC.jpg" data-sub-html="#02">
              <a href="#" class="overlay">
                <img src="portfolio/Project_VIC_small.jpg" alt="Project Vic">
                <div class="item-text">
                  <span class="folio-types">
                    Programme Partner
                  </span>
                  <h3 class="folio-title">Project VIC International</h3>
                </div>
              </a>
              <a href="#" class='details-link' title="details">
                <i class="icon-link"></i>
              </a>
            </div> <!-- end item-wrap -->

            <div id="02" class='hide'>
              <h4>Project VIC International</h4>
              <p>Helping to Identify and Rescue Children from Sexual Exploitation. <a href="https://projectvic.org/katalyst">Details</a></p>
            </div>
          </div> <!-- end folio-item -->

          <div class="brick folio-item">
            <div class="item-wrap "  data-src="portfolio/gallery/EndViolence.jpg"  data-sub-html="#03" >
              <iframe width="565" height="340" src="https://www.youtube.com/embed/F6qbz7xNwEw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            </div> <!-- end item-wrap -->

            <div id="03" class='hide'>
              <h4>EVAC</h4>
              <p>The End Violence Fund serves as a vehicle for change on the ground. We provide financial support to programmes around the world <a href="https://www.end-violence.org/fund/">Details</a></p>
            </div>
          </div> <!-- end folio-item -->



          <div class="brick folio-item">
            <div class="item-wrap "  data-src="/portfolio/gallery/EndViolence.jpg"  data-sub-html="#04" >

              <iframe width="565" height="350" src="https://www.youtube.com/embed/8maa6mCRr2U" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


            </div> <!-- end item-wrap -->

            <div id="04" class='hide'>
              <h4>Katalyst</h4>
              <p>The End Violence Fund serves as a vehicle for change on the ground. We provide financial support to programmes around the world <a href="https://www.end-violence.org/fund/">Details</a></p>
            </div>
          </div> <!-- end folio-item -->

          <div class="brick folio-item">
            <div class="item-wrap "  data-src="portfolio/gallery/EndViolence.jpg"  data-sub-html="#05" >
              <a href="#" class="overlay">
                <img src="portfolio/EndViolence_small.jpg" alt="EVAC">
                <div class="item-text">
                  <span class="folio-types">
                       EVAC
                  </span>
                  <h3 class="folio-title">End Violence Against Children</h3>
                </div>
              </a>
              <a href="#" class='details-link' title="details">
                <i class="icon-link"></i>
              </a>
            </div> <!-- end item-wrap -->

            <div id="05" class='hide'>
              <h4>EVAC</h4>
              <p>The End Violence Fund serves as a vehicle for change on the ground. We provide financial support to programmes around the world <a href="https://www.end-violence.org/fund/">Details</a></p>
            </div>
          </div> <!-- end folio-item -->

          <div class="brick folio-item">
            <div class="item-wrap " data-src="portfolio/gallery/twitter_banner.png" data-sub-html="#06" >
              <a href="#" class="overlay">
                <img src="portfolio/twitter_banner_small.png" alt="Katalyst">
                <div class="item-text"><span class="folio-types">Katalyst</span>
                  <h3 class="folio-title">Project Katalyst</h3>
                </div>
              </a>
              <a href="#" class='details-link' title="details">
                <i class="icon-link"></i>
              </a>
            </div> <!-- end item-wrap -->

            <div id="06" class='hide'>
              <h4>Katalyst</h4>
              <p>KATALYST is developed through active contributions from Law Enforcement, Non-Governmental Organisations and Private Industry. <a href="https://projectvic.org/katalyst">Details</a></p>
            </div>
          </div> <!-- end folio-item -->




        </div> <!-- end folio-wrap -->
      </div> <!-- end twelve -->
    </div> <!-- end portfolio-content -->

  </section>  <!-- end portfolio -->
</template>

<script>
import { page } from 'vue-analytics';

export default {
  name: "ThePortfolio",
  methods: {
    track () {
      page('/portfolio')
    }
  }
}
</script>

<style scoped>

</style>
