<template>
  <section id="clients" style="padding-top: 10%">
    <div class="row narrow section-intro with-bottom-sep animate-this">
      <div class="col-twelve">
        <h1 class="katalyst_fonts_green">Project Partners</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-twelve">

        <div class="katalyst_fonts client-lists owl-carousel">
          <div><img title="Project VIC International" src="partners/project_vic_logo.png" alt="Project Vic"></div>
          <div><img title="Kerala Cyberdome" src="partners/cyberdome.png" alt="Kerala Cyberdome"></div>
          <div><img title="Autopsy" src="partners/autopsy.png" alt="Basis Technology"></div>
          <div><img title="Microsoft" src="partners/microsoft.png" alt="Microsoft"></div>
          <div><img title="End Violence Against Children" src="partners/evac.png" alt="EVAC"></div>
          <div><img title="Kindred Tech, a Pathfinder Labs initiative" src="partners/kindred.jpg" alt="Kindred Tech"></div>
        </div>

      </div> <!-- end col-twelve -->
    </div> <!-- end row -->

  </section> <!-- end partners -->
</template>

<script>
import {page} from "vue-analytics";

export default {
  name: "ThePartners",
  methods: {
    track () {
      page('/partners')
    }
  }
}
</script>

<style scoped>

</style>
