/**
 * Katalyst Website
 * V: 1.1
 * Author: Pathfinder Labs
 */
import { createApp } from 'vue'
import Notifications  from '@kyvg/vue3-notification';
import VueGtag from "vue-gtag";

import App from './App.vue'

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
library.add( fas, fab, far, fad);

createApp(App).use(Notifications).use(VueGtag, {
    config: { id: "GTM-PDQ6FFQ" }
}).component("font-awesome-icon", FontAwesomeIcon).mount('#app')
