<template>
  <section id="blog">
    <div class="row narrow section-intro with-bottom-sep animate-this">
      <div class="col-full">

        <h1>Katalyst Project News</h1>
        <h1></h1>

        <p class="lead">Articles and News from the Katalyst team</p>

      </div> <!-- end col-full -->
    </div> <!-- end row -->
    <div class="row animate-this">
      <div v-for="post in blogPosts.items" v-bind:key="post.sys.id">
        <span class="blog-meta">
          <h1>{{ post.heading }}</h1>
            <div class="lead-minor-container">
              <span class="lead-minor">Published: {{ moment(post.sys.firstPublishedAt).format("ddd MMM DD, YYYY [at] HH:mm a") }}</span>
              <span class="lead-minor updated-lead-minor" v-if="post.sys.firstPublishedAt !== post.sys.publishedAt">Updated: {{ moment(post.sys.publishedAt).format("ddd MMM DD, YYYY [at] HH:mm a")}}</span>
            </div>
        <p v-for="bd in post.postBody.json.content" v-bind:key="bd.index" class="lead">{{ bd.content[0].value }}</p>
        </span>
      </div>

    </div>
  </section>
</template>

<script>
import moment from 'moment';
import { page } from 'vue-analytics';
export default {
  name: "TheBlog",
  data() {

    return {
      blogPosts: []
    }
  },
  async created() {
    this.blogPosts = await this.getPosts()
    this.moment = moment;
  },
  methods: {
    track () {
      page('/blog')
    },
    getPosts: async () => {
      const query = `{
        blogPostCollection {
          items {
            sys {
              id,
              publishedAt,
              firstPublishedAt
            },
            heading,
            postBody {
                json
            },
            postLocation {
              lat,
              lon
            },
            postAuthor {
              contentfulMetadata {
                tags {
                  name
                }
              }
            },
            postMediaCollection {
              items {
                url,
                fileName

              }
            }
          }
        }
      }`
      const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${process.env.VUE_APP_CONTENTFUL_SPACE_ID}`;
      const fetchOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          query
        })
      };

      try {
        // Fetch data from contentful service
        const response = await fetch(fetchUrl, fetchOptions).then(response =>
            response.json()
        );
        return response.data.blogPostCollection;
      } catch (error) {
        throw new Error("Could not receive the data from Contentful!");
      }
    }
  }
}
</script>

<style scoped>
  .blog-meta {
    color: white;
  }
  .blog-meta h1 {
    color: white !important;
    margin-bottom: 0;
  }
  #blog .lead-minor-container {
    display: flex;
  }
  #blog .updated-lead-minor {
    justify-content: flex-end;
  }
</style>