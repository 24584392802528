<template>
  <footer>
    <div class="footer-main">

      <div class="row">

        <div class="col-five tab-full footer-about">

          <h2 >Kindred Tech</h2>

          <p class="katalyst_fonts footer-p">Kindred Tech is a registered charity dedicated to empowering law enforcement agencies around the globe in their fight against child exploitation. By providing specialized training and the very latest in innovative software, we ensure that investigators have the cutting-edge resources they need to tackle these crimes effectively. Our mission is anchored in the belief that geographical location and financial resources should not be barriers to accessing the most advanced tools necessary for combating child exploitation. Through our efforts, we aim to create a unified front against this global issue, ensuring that every agency, is equipped to protect the most vulnerable members of our society with the most sophisticated technology available.</p>

        </div> <!-- end footer-about -->

        <div class="col-three tab-full footer-social">

          <h4 class="">Follow Us.</h4>

          <ul class="katalyst_fonts list-links">
            <li>
              <a class="footer-link" href="https://www.facebook.com/katalysttechforchange"><i class="fab fa-facebook"></i> Katalyst Tech For Change</a>
            </li>

            <li>
              <a class="footer-link" href="https://www.instagram.com/katalyst_kindredtech/"><i class="fab fa-instagram"></i> Katalyst Kindred Tech</a>
            </li>

            <li>
              <a class="footer-link" href="https://www.linkedin.com/groups/12399631"><i class="fab fa-linkedin"></i> Katalyst LinkedIn</a>
            </li>
          </ul>
        </div> <!-- end footer-social -->



      </div> <!-- end row -->

    </div> <!-- end footer-main -->

    <div class="footer-bottom">

      <div class="row">

        <div class="col-twelve">
          <div class="copyright">
            <span>© Copyright Kindred Tech <span ref="copyrightDate">{{ new Date().getFullYear() }}</span>.</span>
            <span>Design by <a href="http://www.styleshout.com/">styleshout</a></span>
            <span>Created by: Kindred Tech, a <a href="https://pathfinderlabs.nz/">Pathfinder Labs</a> initative</span>
          </div>
        </div>

      </div>

    </div> <!-- end footer-bottom -->

    <div id="go-top">
      <a class="smoothscroll" title="Back to Top" href="#top">
        <i class="fa fa-long-arrow-alt-up" aria-hidden="true"></i>
      </a>
    </div>
  </footer>
</template>

<script>

export default {
  name: "TheFooter"


}
</script>

<style scoped>
.footer-link {
	font-family: "montserrat-regular", sans-serif;
}
</style>
