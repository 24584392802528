<template>
  <section id="about" >

    <div class="row narrow section-intro with-bottom-sep animate-this fadeInUp animated">
      <div class="col-full">

        <!--<div class="about-profile-bg"></div>-->

        <div class="intro">
          <h1 class="animate-this">The Goal</h1>
          <!--<p class="lead animate-this">-->
            <aside class="pull-quote">
              <blockquote>
                <p>To develop, supply, and enhance technology that strengthens the effectiveness of online Child Sexual Abuse Investigators.</p>
              </blockquote>
            </aside>

        </div>

      </div> <!-- end col-full  -->
    </div> <!-- end about-wrap  -->

  </section> <!-- end about -->
</template>

<script>
import {page} from "vue-analytics";

export default {
  name: "TheAbout",
  methods: {
    track () {
      page('/about')
    }
  }
}
</script>

<style scoped>
  #about {
    padding-top: 10%;
  }
</style>
