<template>
  <section id="services">

    <div class="row narrow section-intro with-bottom-sep animate-this">
      <div class="col-full">

        <h1>Project Overview</h1>
        <h1></h1>

        <p class="lead">
					KATALYST offers a comprehensive solution designed to eliminate technical obstacles impeding collaborative efforts among countries to put an end to violence against children. Developed using open source platforms, customized tools, and expert forensic knowledge, KATALYST is a framework that evolves through contributions from Law Enforcement, Non-Governmental Organizations, and the private sector. This ongoing evolution ensures that KATALYST remains at the forefront of technological advancements, continuously enhancing its effectiveness in the fight against child abuse. </p>

      </div> <!-- end col-full -->
    </div> <!-- end row -->

    <div class="row services-content">
			<div class="row narrow section-intro with-bottom-sep animate-this">
				<h1>Katalyst Components</h1>
			</div>

      <div class="services-list block-1-2 block-tab-full group">

        <div class="bgrid service-item ">

          <span class="katalyst_icons pathfinder-helpful-icons"><font-awesome-icon :icon="['fas', 'server']"/></span>

          <div class="service-content katalyst_elements">
            <h3 class="h05">Katalyst Server</h3>

            <p>The KATALYST server integrates validated CSAM hash sets and offers a complete media library for easy data management. This enables worldwide investigators to share and use reliable hash sets, enhancing cooperation. KATALYST removes technical hurdles, improving global efforts to safeguard children.
            </p>
          </div>

        </div> <!-- end bgrid -->

        <div class="bgrid service-item ">

          <span class="katalyst_icons"><font-awesome-icon :icon="['fas', 'list-check']"/></span>

          <div class="service-content katalyst_elements">
            <h3 class="h05">Case Management</h3>

            <p>KATALYST's case management feature allows for seamless handling of cases from beginning to end, enabling data integration and team collaboration. It provides a centralized platform for evidence collection from any location and gives team leaders a clear overview of assignments and investigator workloads, leading to better coordination and productivity in teams.
            </p>
          </div>

        </div> <!-- end bgrid -->

				<div class="bgrid service-item ">

				<span class="katalyst_icons"><font-awesome-icon :icon="['fas', 'graduation-cap']"/></span>

					<div class="service-content katalyst_elements">
						<h3 class="h05">Training and Capacity Building</h3>

						<p>
							KATALYST offers specialized training through a network of experts, available online and face-to-face, to boost technical and victim-focused investigation skills. This training, part of the evolving KATALYST framework, equips investigators with modern methods and knowledge about victim issues, enhancing their ability to protect vulnerable groups and fight crimes against children more effectively.
						</p>
					</div>

				</div> <!-- end bgrid -->

				<div class="bgrid service-item">
				<span class="katalyst_icons"><font-awesome-icon :icon="['fas', 'arrows-retweet']"/></span>

				<div class="service-content katalyst_elements">
					<h3 class="h05">Data Sharing and Collaboration</h3>

					<p>
						KATALYST emphasizes cooperation and data exchange, featuring built-in data sharing capabilities by integrating with HERMES, a complete data sharing platform. This allows investigators to securely share data across national or international borders while maintaining control over their information. Additionally, separate KATALYST setups can resolve conflicts and exchange data effectively.
					</p>
				</div>

			</div> <!-- end bgrid -->

        <div class="bgrid service-item ">

          <span class="katalyst_icons"><font-awesome-icon :icon="['fas', 'laptop-code']"/></span>

          <div class="service-content katalyst_elements">
            <h3 class="h05">Digital Forensics</h3>

            <p>The forensic approach of KATALYST leverages top open source platforms such as Autopsy, making KATALYST the essential hub for CSAM Investigators. It enables the integration of computer forensics with the case management platform, incorporating a media management system and specially designed tools. KATALYST provides a streamlined, all-encompassing framework for investigators throughout the entire process.
            </p>
          </div>

        </div> <!-- end bgrid -->

        <div class="bgrid service-item ">

          <span class="katalyst_icons"><font-awesome-icon :icon="['fas', 'photo-film']" /></span>

          <div class="service-content katalyst_elements">
            <h3 class="h05">Katalyst Media Library</h3>

            <p>The KATALYST media library is designed to improve Victim Identification efforts. It serves as a centralized hub for media management and categorization, employing strong classification models and centralized hash cloud technology. Users of the KATALYST media library can generate and share their own hash sets, fostering continuous collaboration.</p>
          </div>

        </div> <!-- end bgrid -->





      </div> <!-- end services-list -->

    </div> <!-- end services-content -->

  </section> <!-- end services -->
</template>

<script>
import { page } from 'vue-analytics';
export default {
  name: "TheWork",
  methods: {
    track() {
      page('/work')
    }
  }
}
</script>

<style scoped>
.pathfinder-helpful-icons {
	--fa-primary-color: #FFF;
	--fa-secondary-opacity: 1.0;
	--fa-secondary-color: rgba(59, 175, 218, 1);
}
</style>
