<template>



  <TheHeader class="headerButton"></TheHeader>
  <TheHome></TheHome>
  <TheAbout></TheAbout>
  <TheWork></TheWork>
  <TheBlog></TheBlog>
  <ThePortfolio></ThePortfolio>
  <ThePartners></ThePartners>
  <TheContacts></TheContacts>
  <TheFooter></TheFooter>
  <ThePreloader></ThePreloader>



</template>

<script>
import TheHeader from "./components/TheHeader";
import TheWork from "./components/TheWork";
import ThePreloader from "./components/ThePreloader";
import TheFooter from "./components/TheFooter";
import TheContacts from "./components/TheContacts";
import ThePortfolio from "./components/ThePortfolio";
import TheAbout from "./components/TheAbout";
import TheHome from "./components/TheHome";
import ThePartners from "@/components/ThePartners";
import TheBlog from "@/components/TheBlog";
/* import TheHeader from "./components/TheHeader"; */

export default {
  name: "app",
  components: {
    TheBlog,
    ThePartners, TheHeader, TheHome, TheAbout, TheWork, ThePortfolio, TheContacts, ThePreloader, TheFooter}
}

</script>

<style>
@media all and (max-width: 479px) {
	.headerButton {display:none;}
}
</style>
