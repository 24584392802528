<template>
  <section id="home">

    <!--<div class="overlay"></div>-->

    <div class="home-content-table">
      <div class="home-content-tablecell">
        <div class="row">
          <div class="col-twelve">

            <h1 class="animate-intro">Katalyst.</h1>
            <p class="sub-title" style="">By Kindred Tech</p>
            <h3 class="animate-intro">
              <a class="smoothscroll" style="color: white;" href="#about">
                Technology for Change
              </a>
            </h3>



          </div> <!-- end col-twelve -->
        </div> <!-- end row -->
      </div> <!-- end home-content-tablecell -->
    </div> <!-- end home-content-table -->

    <ul class="home-social-list">
      <li>
				<a href="https://www.facebook.com/katalysttechforchange"><i class="fab fa-facebook"></i></a>
      </li>

      <li>
				<a href="https://www.instagram.com/katalyst_kindredtech/"><i class="fab fa-instagram"></i></a>
      </li>

      <li>
				<a href="https://www.linkedin.com/groups/12399631"><i class="fab fa-linkedin"></i></a>
      </li>
    </ul> <!-- end home-social-list -->

    <div class="scrolldown">
      <a href="#about" class="scroll-icon smoothscroll">
        Scroll Down
        <i class="fas fa-long-arrow-alt-right" aria-hidden="true"></i>
      </a>
    </div>

  </section> <!-- end home -->
</template>

<script>
import {page} from "vue-analytics";

export default {
  name: "TheHome",
  methods: {
    track () {
      page('/home')
    }
  }
}
</script>

<style scoped>
	.sub-title {
		font-family: "montserrat-bold", sans-serif;
		font-size: 1em;
		position: relative;
		top: -2.5rem;
	}
</style>
