<template>
  <header>

    <div class="header-logo">
      <a href="#">Katalyst</a>
    </div>

    <a id="header-menu-trigger" href="#0">
      <span class="header-menu-text">Menu</span>

    </a>

    <nav id="menu-nav-wrap">

      <a href="#" class="close-button" title="close"><span>Close</span></a>

      <h3>Katalyst.</h3>

      <ul class="nav-list">
        <li class="current"><a class="smoothscroll" href="#home" title="">Home</a></li>
        <li><a class="smoothscroll" href="#about" title="">The Goal</a></li>
        <li><a class="smoothscroll" href="#services" title="">Project Overview</a></li>
        <li><a class="smoothscroll" href="#blog" title="">Project News</a></li>
        <li><a class="smoothscroll" href="#portfolio" title="">Project Media</a></li>
        <li><a class="smoothscroll" href="#clients" title="">Partners</a></li>
        <li><a class="smoothscroll" href="#contact" title="">Contact</a></li>
      </ul>



      <ul class="header-social-list">
        <li>
          <a href="https://facebook.com/groups/katalystpvi"><font-awesome-icon :icon="['fab', 'facebook']" /></a>
        </li>
        <li>
          <a href="https://twitter.com/katalyst_pvi"><font-awesome-icon :icon="['fab', 'twitter']" /></a>
        </li>
        <li>
          <a href="https://instagram.com/katalyst_pvi"><font-awesome-icon :icon="['fab', 'instagram-square']" /></a>
        </li>

        <li>
          <a href="https://www.linkedin.com/groups/12399631"><font-awesome-icon :icon="['fab', 'linkedin']" /></a>
        </li>
      </ul>

    </nav>  <!-- end #menu-nav-wrap -->

  </header>
</template>

<script>
export default {
  name: "TheHeader"
}
</script>

<style scoped>

</style>