<template>
  <section id="contact" style="padding-top: 10%">

    <!--<div class="overlay"></div>-->

    <div class="row narrow section-intro with-bottom-sep animate-this">
      <div class="col-twelve">

        <h1 class="katalyst_fonts_green">Contact Kindred Tech.</h1>
        <h3></h3>
        <p class="lead">Kindred Tech streamlines the process of donating technology and services, making sure they reach the investigators who need them most. Get in touch with us today to learn how you can contribute.</p>
      </div>
    </div> <!-- end section-intro -->

    <div class="row contact-content">

      <div class="col-seven tab-full">

        <h5>Send Us A Message</h5>

        <!-- form -->
        <form name="contactForm" id="contactForm" @submit.prevent="onSubmit">
          <input type="hidden" name="contactSubject" value="Incoming from the Katalyst website">
          <div class="form-field">
            <input v-model="contactName" name="contactName" type="text" placeholder="Name" minlength="2" required="">
          </div>

          <div class="row">
            <div class="col-twelve">
              <div class="form-field">
                <input v-model="contactEmail" name="contactEmail" type="email" id="_replyto" placeholder="Email"  required="">
              </div>
            </div>

          </div>

          <div class="form-field">
            <textarea v-model="contactMessage" name="contactMessage" placeholder="message" rows="10" cols="50" required=""></textarea>
          </div>

          <div class="form-field">
            <button @click="changeState" ref="btnSubmit" class="submitform">Submit</button>
            <div id="submit-loader">
              <div class="text-loader">Sending...</div>
              <div class="s-loader">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div>
            </div>
          </div>

        </form> <!-- end form -->

        <!-- contact-warning -->
        <div id="message-warning"></div>

        <!-- contact-success -->
        <div id="message-success">
          <i class="fa fa-check"></i>Your message was sent, thank you!<br>
        </div>



      </div> <!-- end col-seven -->

      <div class="col-four tab-full contact-info end ">

        <h5>Contact Information</h5>

        <div class="cinfo">
          <h2>Where are we</h2>
          <p class="lead">
            Kindred Tech<br />
            Auckland,
            New Zealand
          </p>
        </div> <!-- end cinfo -->

        <div class="cinfo">
          <h2>Contact us via</h2>
          <p class="lead">
            <a href="mailto:media@katalyst.org?cc=hello@kindredtech.org&subject=Enquiry from Katalyst Website">media@katalyst.org</a><br>
						<a href="mailto:hello@kindredtech.org?subject=Enquiry from Katalyst Website">hello@kindredtech.org</a>
          </p>
        </div> <!-- end cinfo -->



      </div> <!-- end cinfo -->

    </div> <!-- end row contact-content -->
  <notifications position="bottom center"  width="80%"/>
  </section> <!-- end contact -->
</template>

<script>
import { notify } from '@kyvg/vue3-notification';
import { page } from 'vue-analytics';

export default {
  name: "TheContacts",
  data() {
    return {
      contactEmail: '',
      contactName: '',
      contactMessage: ''
    }
  },
  methods: {
    track () {
      page('/contact');
    },
    async onSubmit() {
			// Submit data
      let response = await fetch(`https://formspree.io/f/xoqperay`,{
        body: JSON.stringify({
					contactName: this.contactName,
					contactEmail: this.contactEmail,
					contactMessage: this.contactMessage
				}),
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();

        if (data.ok){
          notify({
            text: `<i class="fas fa-envelope"></i> Thank you, the Katalyst team will be in contact soon`,
            position: 'bottom right',
            type: 'success'
          })

          // Reset the submit button
          this.$refs.btnSubmit.innerHTML = 'Submit';
					// Reset the Form
					document.getElementById('contactForm').reset();
        } else {
          notify({
            text: `<i class="fas fa-exclamation-circle"></i> There was an error completing the form, please ensure you are using a valid email address`,
            position: 'bottom right',
            type: 'warn'
          })
        }

      } else {
        notify({
          text: `Error sending contact request`,
          position: 'bottom right',
          type: 'warn'
        })

      }

    },
    changeState: function(){
      this.$refs.btnSubmit.innerHTML = '<i class="fas fa-cog fa-spin"></i> Working';
    }

  }
}
</script>

<style scoped>

</style>
